import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';

import { VolumeSortiesData } from "../../models/shopData";
import dayjs from 'dayjs';

import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import { getDatesLabelSemaine } from '../../utils/Charts';
import useWindowDimensions from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';
import { getDateAsString, getRefresh } from '../../utils/Widgets';
import { getVolumeSorties } from '../../api/locationApi';
import { MultiSeriesLineChart } from '../Charts/MultiSeriesLineChart';
import ChartData from '../../models/chartData';
import WidgetSlider from '../WidgetSlider';

interface VolumeSortiesEvolutionProps {
    shopId: number,
    widget: DashboardWidget
}

interface VolumeSortiesEvolutionSlideProps {
    shopId: number;
    slide: DashboardWidgetSlide
    widget: DashboardWidget,
    group: 'w' | 'd'
}


export default function VolumeSortiesEvolution({shopId, widget} : VolumeSortiesEvolutionProps) {

    const top: number = 10;
    const [fixedSlide, setFixedSlide] = useState<DashboardWidgetSlide>({debut:'@EXERCICEDEBUT@', fin:dayjs().format('YYYY-MM-DD'), timestamp:null})
    const [slides, setSlides] = useState<DashboardWidgetSlide[]>([
        {
            debut: dayjs().add(-92, 'day').format('YYYY-MM-DD'),
            fin: dayjs().add(-62, 'day').format('YYYY-MM-DD'),
            timestamp: null
        },
        {
            debut: dayjs().add(-61, 'day').format('YYYY-MM-DD'),
            fin: dayjs().add(-31, 'day').format('YYYY-MM-DD'),
            timestamp: null
        },
        {
            debut: dayjs().add(-30, 'day').format('YYYY-MM-DD'),
            fin: dayjs().format('YYYY-MM-DD'),
            timestamp: null
        }
        
    ])

    return (
       <div className="Widget" style={{minHeight:'600px'}}>
            <WidgetHeader title="Evolution des quantités sorties" widget={widget} withFixedFrame={true}/>
            {widget.fixedFrame ? 
                <VolumeSortiesEvolutionSlide 
                    widget={widget}
                    shopId={shopId} 
                    slide={fixedSlide}
                    group='w' />
                    :
                    <WidgetSlider widget={widget} defaultIndex={slides.map(el => el.fin).indexOf(dayjs().format('YYYY-MM-DD'))}>
                        {slides.map(s => {
                            return (
                            <VolumeSortiesEvolutionSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={s} 
                                key={s.debut}
                                group='d' />)
                        })}
                    </WidgetSlider>
                    
            
           
          }
       </div>
    )
}


function VolumeSortiesEvolutionSlide ({shopId, widget, slide, group} : VolumeSortiesEvolutionSlideProps) {

    const [data, setData] = useState<VolumeSortiesData | null>(null);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        setData(null);
        setError(null);
        getVolumeSorties(shopId, slide.debut, slide.fin, group, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(err.message);
        });
        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent 
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(data?.dateDebut || null, data?.dateFin || null, useWindowDimensions().width < smallWidth)}>
            {data == null ? 
                null :
                <div>
                    <MultiSeriesLineChart
                        style={{height:'600px'}}
                        labels={getDatesLabelSemaine(data.dateDebut, data.dateFin, group === 'w')}
                        series={
                            [
                                {
                                    name:'TOTAL',
                                    data: getVolumeSortiesDataTotal(data)
                                },
                                ...data.detail.map(el => {
                                    return {
                                        name:el.libelle,
                                        data: el.dates.map(d => {
                                            return {
                                                name: d.date,
                                                valueN: d.nbArticleN,
                                                valueN_1: d.nbArticleN_1
                                            }
                                        })
                                    }
                                })
                           
                            ]
                        }
                    />
                </div>
            }
        </WidgetContent>
    )
}

function getVolumeSortiesDataTotal (data: VolumeSortiesData): ChartData[] {
    let dates: ChartData[] = [];
    data.detail.map(el => {
        el.dates.map(d => {
            let index = dates.map(e => e.name).indexOf(d.date);
            if (index >= 0) {
                dates[index].valueN += d.nbArticleN;
                dates[index].valueN_1 += d.nbArticleN_1;
            }
            dates.push({
                name: d.date,
                valueN: d.nbArticleN,
                valueN_1: d.nbArticleN_1
            })
        })
    })

    return dates;
}