import { useNavigate, useParams } from "react-router-dom";
import WidgetContainer from "../components/WidgetContainer";
import PerformancesParCategories from "../components/widgets/PerformancesParCategories";
import { DashboardHeader, MainHeader } from "../components/Headers";
import { BackIcon, HeartIcon, MountainsIcon } from "../components/Icons";
import { getGroupColorTheme, getShopName } from "../utils/Shops";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { User } from "../models/user";
import { DashboardWidget, Widget } from "../models/widget";
import { useEffect } from "react";
import { setWidgets } from "../features/DashboardSlice";
import PrevisionsSorties from "../components/widgets/PrevisionsSorties";
import AvancementSorties from "../components/widgets/AvancementSorties";
import ChiffreAffaires from "../components/widgets/ChiffreAffaires";
import PrevisionsRetours from "../components/widgets/PrevisionsRetours";
import AvancementRetours from "../components/widgets/AvancementRetours";
import CaisseVente from "../components/widgets/CaisseVente";
import CaisseLocation from "../components/widgets/CaisseLocation";
import CALocationPrevisionnel from "../components/widgets/CALocationPrevisionnel";
import PrixMoyenCategories from "../components/widgets/PrixMoyCategorie";
import Palmares from "../components/widgets/Palmares";
import CAEvolution from "../components/widgets/CAEvolution";

import '../styles/dashboard.css';
import CaisseModePaiement from "../components/widgets/CaisseModePaiement";
import VolumeSorties from "../components/widgets/VolumeSorties";
import VolumeSortiesEvolution from "../components/widgets/VolumeSortiesEvolution";

export default function Dashboard () {

  const shopId  = parseInt(useParams().shopId || '');
  const type = useParams().type || 'favoris';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user: User = useAppSelector((state) => state.user);
  const widgets = useAppSelector((state) => state.dashboard.widgets);

  const color: string | null = shopId < 0 ? user.groups.find(el => el.id === -shopId)?.color || null : null ;

  const displayPalmares: boolean = shopId > 0 ? true : (shopId == 0 ? !user.multibase : !user.groups.find(el => el.id === -1*shopId)?.multibase)
  const widgetPalmaresId = [9, 10, 11, 12, 13, 14, 17, 18];

  useEffect(() => {
    let widgets: DashboardWidget[] = getWidgets(type, user.widgets);
    if (!displayPalmares) widgets = widgets.filter (el => !widgetPalmaresId.includes(el.id));
    dispatch(setWidgets(widgets));

  }, [type, user.widgets]);

  return (
    <div style={getGroupColorTheme(color)}>
        <MainHeader title={getShopName(shopId, user)}>
          <button onClick={() => navigate('/')}><BackIcon/></button>
        </MainHeader>
        <DashboardHeader type={type} shopId={shopId} displayPalmares={displayPalmares}/>
        { type === 'favoris' && widgets.length == 0 ?
          <NoFavoritesFiller/> :
          <WidgetContainer>
            {widgets.map ((w: DashboardWidget) => {
              return getWidgetById(shopId, w)
            })}
          </WidgetContainer>


        }
    </div>
  );
}


function NoFavoritesFiller () {
  return (
    <div className="NoFavoritesFiller">
      <MountainsIcon/>
      <div>

        <span>
          Votre tableau de bord d'accueil est vide! Cliquez sur l'icone 
          <HeartIcon/>
          pour ajouter des widgets à votre page d'accueil.
        </span>
      </div>
      
    </div>
  )
}


function getWidgets(dashboardType: string, favoriteWidgets: Widget[]): DashboardWidget[] {

  let selectedWidgets: Widget[] = [];
  

  if (dashboardType === 'favoris') {
    selectedWidgets = favoriteWidgets.filter(el => el.favorite);
  }
  else {
    let widgets: number[] = [];
  
    if (dashboardType === 'location') widgets = [1, 2, 3, 4, 5, 21, 22];
    else if (dashboardType === 'caisse') widgets = [6, 19, 7, 8, 15, 16, 20];
    else if (dashboardType === 'palmares') widgets = [17, 9, 11, 10, 18, 12, 13, 14];

    selectedWidgets = favoriteWidgets.filter(el => widgets.includes(el.id)).sort((a,b) => widgets.indexOf(a.id) > widgets.indexOf(b.id) ? 1 : -1);
  } 
  
  return selectedWidgets
        .map(el => {
          return {
            id: el.id,
            favorite: el.favorite || false,
            chartType: el.favoriteChartType || null,
            slideIndex: el.startIndex || null,
            occurence:0, 
            search:false, 
            searchDateDebut:null, 
            searchDateFin:null, 
            fixedFrame:false, 
            refreshTime: null,
            slides: [],
            searchSlide: {debut: "", fin: "", timestamp: null}
          }
        })


}

function getWidgetById (shopId: number, widget: DashboardWidget): React.ReactNode | null {
    switch (widget.id) {
        case 1 :
            return <PerformancesParCategories shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 2 :
            return <PrevisionsSorties shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 3 :
            return <PrevisionsRetours shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 4 :
            return <AvancementSorties shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 5 :
            return <AvancementRetours shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 6 :
            return <ChiffreAffaires shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 7 :
            return <CaisseVente shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 8 :
            return <CaisseLocation shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 9 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="top" of="marques"/>
        case 10 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="top" of="produits"/>
        case 11 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="top" of="fournisseurs"/>
        case 12 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="flop" of="marques"/>
        case 13 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="flop" of="fournisseurs"/>
        case 14 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="flop" of="produits"/>
        case 15 :
            return <CALocationPrevisionnel shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 16 :
            return <PrixMoyenCategories shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 17 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="top" of="familles"/>
        case 18 :
            return <Palmares shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget} type="flop" of="familles"/>
        case 19 :
            return <CAEvolution shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 20 :
            return <CaisseModePaiement shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 21 :
            return <VolumeSorties shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        case 22 :
            return <VolumeSortiesEvolution shopId={shopId} key={widget.id+"_"+widget.occurence} widget={widget}/>
        default: 
            return null;
    }

}



