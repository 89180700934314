import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { formatLabels } from "../../utils/Charts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import { smallWidth } from "../../utils/Const";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";

interface GapBarChartProps {
    data: ChartData[];
    style?: CSSProperties;
}



export function GapBarChart ({data, style} : GapBarChartProps){

    const {width} = useWindowDimensions();
    // let colors = getBarChartColorTheme(theme, useWindowTheme() === "dark");
    let colors = getGapBarChartColorTheme(useWindowTheme() === "dark");

    const fontSize = width < smallWidth ? 10 : 12;

    style = {
        ...style,
        width:'96%'
    }
  
    let options: EChartOption = {
        legend: {
            show:false,
            // itemWidth: 0,
            // itemHeight: 0,
            // selectedMode:false,
            // data: [
            //     {
            //         name: 'ecart',
            //         textStyle: {
            //             color: colors.legendN,
            //             fontWeight: 500,
            //             fontSize: 13
            //         }
            //     }
            // ]
        },
        grid: {
            left: 10,
            top: 20,
            right:10,
            bottom: 0,
            containLabel :true
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
                fontSize : fontSize,
            },
            splitLine: {
                lineStyle: {
                    color: colors.line
                }
            }
        },
        xAxis: {
            type: 'category',
            data: formatLabels(data.map((el: ChartData) => el.name), 15),
            axisLabel: {
                interval : 0,
                height: 50,
                fontSize : fontSize,
                color:colors.text
    
            },
            axisLine: {
                lineStyle: {
                    color:colors.line
                }
            }
        },
        series: [
            {
                type: 'bar',
                name:"N",
                data: data.map((el: ChartData) => getEcartValues(el.valueN, el.valueN_1)),
                label: {
                    show :true,
                    fontSize : fontSize,
                    position: 'inside',
                    color: colors.label,
                    formatter: (el: any) => (el.data >= 0 ? "+" : "") + el.data + "%"
                },
                itemStyle : { 
                    color: (el: any) => {
                        return el.data > 0 ? colors.green : colors.red;
                    },
                }
            }
        ]
        }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}

const getEcartValues = (valueN: number, valueN_1: number) => {
    if (valueN_1 == 0) {
        if (valueN > 0) return 100;
        return 0;
    }
    return parseFloat(((valueN - valueN_1) * 100 / valueN_1).toFixed(1));
}


const getGapBarChartColorTheme = (darkTheme:boolean) => {

    if (darkTheme) return getGapBarChartColorThemeDark();

    return {
        text : "#8a8b93", 
        line: "#d5d7e6",
        green: '#47bf67',
        red: '#e64c3e',
        label: '#ffffff'
    }
  
}

const getGapBarChartColorThemeDark = () =>{

    return {
        text : "#8a8b93", 
        line: "#d5d7e6",
        green: '#47bf67',
        red: '#e64c3e',
        label: '#ffffff'
    }
}