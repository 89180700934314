import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { CSSProperties } from "react";
import { ChartSerie } from "../../models/chartData";
import useWindowDimensions from "../../app/hooks";
import { smallWidth } from "../../utils/Const";
import { formatDateLabels } from "../../utils/Charts";

interface MultiSeriesLineChartProps {
  labels: string[]
    series: ChartSerie[];
    style?: CSSProperties;
}


const colors: string[] = ['#718DC2', '#8dc73a', '#f5a278', '#e96a9b', '#9d6fe3', '#e6b839', '#298c48', '#475cc9']


export function MultiSeriesLineChart ({labels, series, style} : MultiSeriesLineChartProps){

    const {width} = useWindowDimensions();
    const tooltip: EChartOption.Tooltip = {trigger: width > smallWidth ? 'axis':'none'}; 
    const fontSize = width < smallWidth ? 10 : 12;

    // const colors = getChartColorTheme(theme, useWindowTheme() === "dark");
    style = {
        ...style,
        width:'96%'
    }

    let options: EChartOption = {
        tooltip: tooltip,
          legend: {
            show:true,
            selectedMode: 'multiple',
            bottom: 0,
            data: [
                ...series.map((s, i) => {
                    return {
                        name: s.name+" N",
                        textStyle: {
                            color: colors[i],
                        }
        
                    }
                }),
                ...series.map((s, i) => {
                    return {
                        name: s.name+ "N-1",
                        textStyle: {
                            color: colors[i],
                        }
        
                    }
                }),
            ]
        },
        grid: {
            left: 10,
            top: 20,
            right: 10,
            bottom: 80,
            containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: formatDateLabels(labels),
          axisLabel:{
            rotate:45,
            fontSize: fontSize
          }
        },
        yAxis: {
          // min: Math.floor(Math.min(...data.map(el => Math.min(el.valueN, el.valueN_1)))*0.9),
          type: 'value',
          axisLabel:{
            fontSize: fontSize
          }
        },
        series: [
            ...series.map((s, i) => {
                return {
                    name: s.name + " N",
                    data: labels.map (l => s.data.find(el => el.name === l)?.valueN || 0),
                    lineStyle: {
                        color: colors[i],
                        type:'solid'
                    },
                    itemStyle: {
                        color: colors[i],
                    },
                    type: 'line',
                    
                    smooth:true
                }
            }),   
            ...series.map((s, i) => {
                return {
                    name: s.name+ " N-1",
                    data: labels.map (l => s.data.find(el => el.name === l)?.valueN_1 || 0),
                    lineStyle: {
                        color: colors[i],
                        type:'dotted'
                    },
                    itemStyle: {
                        color: colors[i],
                    },
                    type: 'line',
                    smooth:true
                }
            }),  
        ]
    }
  
    return  <ReactECharts option={options} style={style}/> 
  
}